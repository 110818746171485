<template>
    <div class="topBar"  :style="{backgroundColor: bgc}">
        <van-row>
            <van-col span="6"><div @click="backTop" v-if="left"><van-icon name="arrow-left"/></div></van-col>
            <van-col span="12"><span class="pageTitle" :style="{color: color}">{{title}}</span></van-col>
            <van-col span="6"></van-col>
        </van-row>
    </div>
</template>
import wx from ‘weixin-js-sdk’
<script>
export default {
    name:"topBar",
    props: {
        left: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '街道果果'
        },
        bgc: {
            type: String,
            default: '#fff'
        },
        color: {
            type: String,
            default: '#000'
        }
    },
    methods : {
        backTop () {
          var ua = window.navigator.userAgent.toLowerCase();
          if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            wx.miniProgram.getEnv(function(res) {
              if (res.miniprogram) {
                wx.miniProgram.switchTab({url:'/pages/work/work'})
              }else {
                this.$router.go(-1)
              }
            })
          }else{
            this.$router.go(-1)
          }
          // if(window.__wxjs_environment === 'miniprogram'){
          //   wx.miniProgram.switchTab({url:'/pages/home/home'})
          // }else {
          //   this.$router.go(-1)
          // }
        }
    }
}
</script>
<style lang="scss" scoped>
.van-icon  {
    font-size: 40px;
    line-height: 100px;
    text-align: center;
}
.topBar {
  display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    line-height: 100px;
    padding: 0 44px;
    .pageTitle {
        display: block;
        text-align: center;
        font-size: 36px;
        color: #333;
        font-family: PingFang SC;
        // font-weight: bold;
    }
}
</style>
