<template>
  <div>
    <div id="watermark">
      <van-form>
        <van-tabs v-model="active" color="#409EFF">
          <van-tab title="居民">
            <div class="info">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span v-if="control.disabled" class="form-title-view">居民信息</span>
                  <span v-if="!control.disabled" class="form-title-view">编辑</span>
                  <div v-if="!!ranDomData.checkId" style="float: left;color: #E9564B;margin-top: 2px;">{{ranDomData.cause}}</div>
                </template>
                <template #default>
                  <van-uploader :readonly="control.disabled" :after-read="cardIdIdentified">
                    <div>
                      <van-icon size="25" :color="control.disabled ? '#ccc' : '#3095FF'" name="photograph"/>
                    </div>
                  </van-uploader>
                </template>
              </van-cell>
              <van-uploader :readonly="control.disabled" :after-read="headImgButton">
                <van-cell-group>
                  <van-cell title="照片" :is-link="!control.disabled" :readonly="control.disabled">
                    <template>
                      <img class="headImg" :src="show.headImg" alt="">
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-uploader>
              <van-cell title="姓名" required>
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="control.disabled"
                    v-model="dataForm.name"
                    name="姓名"
                    placeholder="请输入姓名或者拍照识别"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell title="曾用名">
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="control.disabled"
                    v-model="dataForm.usedName"
                    name="曾用名"
                    placeholder="请输入曾用名"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell name="性别" title="性别"
                        :value-class="{'value-common':dataForm.sex=='请选择'}"
                        :value="dataForm.sex == 1 ? '男' : '女'" :rules="[{ required: true, message: '请选择性别' }]"
                        @click="control.sex = !control.disabled"
                        readonly
                        clickable
                        placeholder="请选择性别"
                        :is-link="!control.disabled"
              />
              <van-popup v-model="control.sex" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="[{label: '男', value: 1}, {label: '女', value: 2}]"
                    @confirm="sexConfirm"
                    @cancel="control.sex = false"
                />
              </van-popup>

              <van-cell
                  readonly
                  clickable
                  :value-class="{'value-common':show.nationalityStr=='请选择'}"
                  :value="show.nationalityStr"
                  name="国籍"
                  title="国籍"
                  placeholder="请选择国籍"
                  @click="goNationality"
                  :rules="[{ required: true }]"
                  :is-link="!control.disabled"
              />

              <van-cell
                  readonly
                  clickable
                  :value="show.nationStr"
                  :value-class="{'value-common':show.nationStr=='请选择'}"
                  name="民族"
                  title="民族"
                  placeholder="请选择民族"
                  @click="goNation"
                  :rules="[{ required: true }]"
                  :is-link="!control.disabled"
              />

              <van-field
                  :readonly="control.disabled"
                  clickable
                  v-model="dataForm.hometown"
                  :value-class="{'value-common':show.Hometown=='请选择'}"
                  name="籍贯"
                  label="籍贯"
                  placeholder="请输入籍贯如: 浙江杭州"
                  :rules="[{ required: true }]"
              />
              <van-popup v-model="control.Hometown" position="bottom">
                <van-cascader title="请选择" :options="selectList.hometownList"
                              @close="control.Hometown = false" @finish="hometownFinish" :field-names="fieldNames"/>
              </van-popup>

              <van-cell
                  readonly
                  clickable
                  name="出生日期"
                  :value="dataForm.birthday"
                  title="出生日期"
                  placeholder="请选择出生日期"
                  :value-class="{'value-common':dataForm.birthday=='请选择'}"
                  :rules="[{ required: true }]"
                  @click="showBirthday"
                  :is-link="!control.disabled"
              />
              <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa"
                            :default-date="new Date(1997,0,1)" :min-date="new Date(1945,0,1)" :max-date="new Date()"
                            @confirm="birthdayCalendar"/>
              <van-cell title="手机号码" required>
                <van-field
                    clearable
                    :readonly="control.disabled"
                    v-model="dataForm.mobile"
                    name="手机号码"
                    placeholder="请输入手机号码"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell title="固定电话">
                <van-field
                    clearable
                    :readonly="control.disabled"
                    v-model="dataForm.fixedLine"
                    name="固定电话"
                    placeholder="请输入固定电话"
                />
              </van-cell>
              <van-cell
                  readonly
                  clickable
                  name="证件类型"
                  :value="show.idNumberTypeStr"
                  :value-class="{'value-common':show.idNumberTypeStr=='请选择'}"
                  title="证件类型"
                  placeholder="点击选择证件类型"
                  @click="showIdNumberType"
                  :is-link="!control.disabled"
              />
              <van-popup v-model="control.idNumberType" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.idNumberTypeList"
                    @confirm="idNumberType"
                    @cancel="control.idNumberType = false"
                />
              </van-popup>
              <van-cell title="有效证件号" required>
                <van-field
                    clearable
                    :readonly="control.disabled"
                    @blur="getInfoByIdNumber"
                    v-model="dataForm.idNumber"
                    name="有效证件号"
                    placeholder="请输入有效证件号"
                    :rules="[{ required: true }]"
                />
              </van-cell>

              <van-calendar v-model="control.deathDate" :default-date="new Date()" :min-date="new Date(2000,0,1)"
                            :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="deathDateCalendar"/>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">户籍信息</span>
                  </template>
                </van-cell>
                <van-cell title="户籍地址">
                  <van-field
                      clearable
                      :readonly="control.disabled"
                      v-model="dataForm.regAddress"
                      name="户籍地址"
                      type="textarea"
                      autosize
                      placeholder="请输入户籍地址"
                  />
                </van-cell>
                <van-cell title="现居地">
                  <van-field
                      clearable
                      :readonly="control.disabled"
                      v-model="dataForm.nowAddress"
                      name="现居地"
                      type="textarea"
                      autosize
                      placeholder="请输入现居地址"
                  />
                </van-cell>
                <van-cell
                    readonly
                    required
                    name="所在社区及相关户籍类型"
                    title="所在社区及相关户籍类型">
                    <template slot="default">
                      <span v-if="!control.disabled" style="color:#387FF5" @click="addMsgShow=true">添加</span>
                    </template>
                </van-cell>

                <!-- 添加户籍信息 -->
                  <van-popup v-model="control.addLabel" position="bottom"
                            @click-overlay="addLabelOverlay">
                    <van-cascader
                        title="请选择"
                        value="value"
                        :field-names="{ text: 'label', value: 'value', children: 'children' }"
                        :options="selectList.userCommunityLabelTree"
                        active-color="#1989fa"
                        @close="control.addLabel=false"
                        @change="addLabelFinish"
                    />
                  </van-popup>
                  <van-popup v-model="control.addRegistryType" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="label"
                        :columns="selectList.registryTypeList"
                        @confirm="addRegistryType"
                        @cancel="control.addRegistryType = false"
                    />
                  </van-popup>
                  <van-popup v-model="control.addCommunity" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="label"
                        :columns="selectList.communityList.filter(item =>{return dataForm.userResList.map(val =>{return val.orgName}).indexOf(item.label)==-1})"
                        @confirm="addCommunity"
                        @cancel="control.addCommunity = false"
                    />
                  </van-popup>
                <van-dialog v-model="addMsgShow" :showConfirmButton="false">
                  <template #title>
                    <div style=" position: relative;font-size: 16px;margin-bottom: 25px;">
                      添加信息
                      <van-icon name="cross" class="crossBtn" @click="addMsgShow = false" />
                    </div>
                  </template>
                  <van-cell
                      readonly
                      clickable
                      name="社区"
                      required
                      :value="newUserInfo.orgName"
                      title="社区"
                      :value-class="{'value-common':newUserInfo.orgName=='请选择'}"
                      placeholder="请选择"
                      :rules="[{ required: true }]"
                      @click="control.addCommunity=true"
                      is-link
                  />
                  <van-cell
                      readonly
                      clickable
                      name="户籍类型"
                      required
                      :value="newUserInfo.userRes.registryTypeStr"
                      title="户籍类型"
                      :value-class="{'value-common':newUserInfo.userRes.registryTypeStr=='请选择'}"
                      placeholder="请选择"
                      :rules="[{ required: true }]"
                      @click="control.addRegistryType=true"
                      is-link
                  />
                  <van-cell
                      readonly
                      clickable
                      name="标签管理"
                      :value="show.labelStr || '请选择'"
                      title="标签管理"
                      is-link
                      @click="control.addLabel=true"
                  />

                  <van-cell hover-class="none" v-if="newUserInfo.labels.length>0">
                    <template v-for="item in newUserInfo.labels">
                      <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                        <span :style="control.disabled ? 'border: none' : ''">{{ item.name }}</span>
                        <van-icon style="padding: 0 10px;" color="#007AFF" name="cross" v-if="!control.disabled" @click="removeAddLabel(item.value)"/>
                      </div>
                    </template>

                  </van-cell>
                  <div style="width:100%;padding:0 60px;margin:10px 0 20px 0">
                    <van-button round type="info" block @click="addUserInfo()">确认添加</van-button>
                  </div>
                </van-dialog>

                <van-tabs v-model="communityActive" color="#387FF5" title-active-color="#387FF5" v-if="dataForm.userResList.length!=0">
                  <template v-for="(item, index) in dataForm.userResList">
                    <van-tab :key="index">
                      <template #title>{{item.orgName}} <van-icon v-if="dataForm.userResList.length>1&&!control.disabled" name="cross" @click="deleteUserRes(index)"/></template>
                      <van-cell
                          readonly
                          clickable
                          name="户籍类型"
                          :value="item.userRes.registryTypeStr || item.registryTypeStr || '未知'"
                          title="户籍类型"
                          :value-class="{'value-common':item.userRes.registryTypeStr=='请选择'}"
                          placeholder="请选择户籍类型"
                          :rules="[{ required: true }]"
                          @click="showRegistryType"
                          :is-link="!control.disabled"
                      />
                      <van-popup v-model="control.registryType" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="label"
                            :columns="selectList.registryTypeList"
                            @confirm="registryType"
                            @cancel="control.registryType = false"
                        />
                      </van-popup>

                      <van-cell
                          readonly
                          clickable
                          name="标签管理"
                          :value="show.labelStr || '请选择'"
                          title="标签管理"
                          :is-link="!control.disabled"
                          @click="showlabel"
                      />
                      <van-popup v-model="control.label" position="bottom"
                                @click-overlay="labelOverlay">
                        <van-cascader
                            title="请选择"
                            value="value"
                            :field-names="{ text: 'label', value: 'value', children: 'children' }"
                            :options="selectList.userCommunityLabelTree"
                            active-color="#1989fa"
                            @close="labelClose"
                            @change="labelFinish"
                        />
                      </van-popup>

                      <van-cell hover-class="none" v-if="item.labels&&item.labels.length>0">
                        <template v-for="val in item.labels">
                          <div class="vanTag" v-if="val.rightShow && !val.delete" :key="val.id" >
                            <span :style="control.disabled ? 'border: none' : ''">{{ val.name }}</span>
                            <van-icon style="padding: 0 10px;" color="#007AFF" name="cross" v-if="!control.disabled" @click="removeLabel(val.value)"/>
                          </div>
                        </template>

                      </van-cell>

                      <van-cell-group class="info-second" v-if="control.isRandomShow">
                        <van-cell class="info-title">
                          <template #title>
                            <div class="blue-box"></div>
                            <span class="form-title-view">抽查结果</span>
                          </template>
                        </van-cell>

                        <van-cell title="抽查结果" required>
                          <van-radio-group v-model="ranDomData.checkReason" direction="horizontal" :disabled="ranDomData.readonly">
                            <van-radio :name="1">合格</van-radio>
                            <van-radio :name="2">不合格</van-radio>
                          </van-radio-group>
                        </van-cell>

                        <van-cell
                            v-if="ranDomData.checkReason == 2"
                            class="required"
                            readonly
                            required
                            clickable
                            name="数据类型"
                            :value="ranDomData.dataTypeStr"
                            :value-class="{'value-common':ranDomData.dataTypeStr=='请选择'}"
                            title="数据类型"
                            placeholder="请选择"
                            @click="ranDomData.dataTypeShow = !ranDomData.readonly"
                            :is-link="!control.disabled"
                        />
                        <van-popup v-model="ranDomData.dataTypeShow" position="bottom">
                          <van-picker
                              show-toolbar
                              value-key="label"
                              :columns="ranDomData.dataTypeList"
                              @confirm="dataTypeConfirm"
                              @cancel="ranDomData.dataTypeShow = false"
                          />
                        </van-popup>

                        <van-cell title="不合格原因" required v-if="ranDomData.checkReason == 2">
                          <van-field
                              clearable
                              :readonly="ranDomData.readonly"
                              v-model="ranDomData.noCheckReason"
                              type="textarea"
                              autosize
                              name="不合格原因"
                              placeholder="请输入"
                          />
                        </van-cell>
                      </van-cell-group>
                    </van-tab>
                  </template>
                </van-tabs>

              </van-cell-group>
              <van-cell-group class="info-second" v-if="!ranDomData.user">
                <van-collapse v-model="collapse">
                  <van-collapse-item name="1">
                    <template #title>
                      <div class="blue-box"></div>
                      <span class="form-title-view">附属信息</span>
                    </template>
                    <van-cell
                        :value="show.marriage"
                        name="婚姻"
                        title="婚姻"
                        placeholder="请选择是否已婚"
                        :value-class="{'value-common':show.marriage=='请选择'}"
                        @click="showMarriage"
                        :rules="[{ required: true }]"
                        :is-link="!control.disabled"
                    />
                    <van-popup v-model="control.marriage" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.marriageList"
                          @confirm="marriageConfirm"
                          @cancel="control.marriage = false"
                      />
                    </van-popup>
                    <van-cell
                        :readonly="control.disabled"
                        v-model="show.spouseIdNumberType"
                        @click="showspouseIdNumberType"
                        name="配偶证件类型"
                        title="配偶证件类型"
                        :value-class="{'value-common':show.spouseIdNumberType=='请选择'}"
                        placeholder="请选择配偶证件类型"
                        :rules="[{ required: true }]"
                        :is-link="!control.disabled"
                    />
                    <van-popup v-model="control.spouseIdNumberType" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.idNumberTypeList"
                          @confirm="spouseIdNumberTypeConfirm"
                          @cancel="control.spouseIdNumberType = false"
                      />
                    </van-popup>
                    <van-cell title="配偶证件号">
                      <van-field
                          clearable
                          :readonly="control.disabled"
                          v-model="dataForm.spouseIdNumber"
                          name="配偶证件号"
                          placeholder="请输入配偶证件号"
                      />
                    </van-cell>
                    <van-cell
                        :readonly="control.disabled"
                        v-model="show.education"
                        name="学历"
                        title="学历"
                        :value-class="{'value-common':show.education=='请选择'}"
                        placeholder="请选择学历"
                        @click="showEducation"
                        :rules="[{ required: true }]"
                        :is-link="!control.disabled"
                    />
                    <van-popup v-model="control.education" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.educationList"
                          @confirm="educationConfirm"
                          @cancel="control.education = false"
                      />
                    </van-popup>
                    <van-cell
                        :readonly="control.disabled"
                        v-model="show.political"
                        name="政治面貌"
                        title="政治面貌"
                        :value-class="{'value-common':show.political=='请选择'}"
                        @click="showPolitical"
                        placeholder="请选择政治面貌"
                        :rules="[{ required: true }]"
                        :is-link="!control.disabled"
                    />
                    <van-popup v-model="control.political" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.politicalList"
                          @confirm="politicalConfirm"
                          @cancel="control.political = false"
                      />
                    </van-popup>
                    <van-cell
                        :readonly="control.disabled"
                        v-model="show.religious"
                        name="宗教信仰"
                        title="宗教信仰"
                        @click="showReligious"
                        :value-class="{'value-common':show.religious=='请选择'}"
                        placeholder="请选择宗教信仰"
                        :rules="[{ required: true }]"
                        :is-link="!control.disabled"
                    />
                    <van-popup v-model="control.religious" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.religiousList"
                          @confirm="religiousConfirm"
                          @cancel="control.religious = false"
                      />
                    </van-popup>
                    <van-cell title="工作单位">
                      <van-field
                          clearable
                          :readonly="control.disabled"
                          v-model="dataForm.company"
                          name="工作单位"
                          placeholder="请输入工作单位"
                      />
                    </van-cell>
                    <van-cell title="紧急联系人">
                      <van-field
                          clearable
                          :readonly="control.disabled"
                          v-model="dataForm.emContact"
                          name="紧急联系人"
                          placeholder="请输入紧急联系人"
                      />
                    </van-cell>
                    <van-cell title="联系人手机号">
                      <van-field
                          clearable
                          :readonly="control.disabled"
                          v-model="dataForm.emMobile"
                          name="联系人手机号"
                          placeholder="请输入联系人手机号"
                      />
                    </van-cell>
                    <van-field
                        clearable
                        clear-trigger="always"
                        :readonly="control.disabled"
                        :border="false"
                        class="left"
                        v-model="dataForm.resRemark"
                        rows="4"
                        autosize
                        type="textarea"
                        placeholder="请输入备注"
                        input-align="left"
                    />
                  </van-collapse-item>
                </van-collapse>
              </van-cell-group>


              <van-cell-group class="info-second" v-if="!control.isRandomShow">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="form-title-view">是否死亡</span>
                  </template>
                  <template #default>
                    <van-switch v-model="death" :disabled="control.disabled"/>
                  </template>
                </van-cell>

                <van-field
                    v-if="dataForm.death==1"
                    readonly
                    clickable
                    name="死亡时间"
                    :value="dataForm.deathDate"
                    label="死亡时间"
                    placeholder="点击选择死亡时间"
                    @click="showDeathDate"
                />
              </van-cell-group>
              <div class="userRes"></div>
            </div>
          </van-tab>
          <van-tab title="就业信息">
            <div class="info">
              <van-cell-group>
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">就业信息</span>
                  </template>
                </van-cell>
                <van-field
                    :readonly="control.disabled"
                    v-model="dataForm.professional"
                    name="专业能力"
                    clearable
                    label="专业能力"
                    placeholder="请输入专业能力"
                    :rules="[{ required: true }]"
                />
                <van-field
                    :readonly="control.disabled"
                    v-model="dataForm.amateur"
                    name="业余特长"
                    clearable
                    label="业余特长"
                    placeholder="请输入业余特长"
                    :rules="[{ required: true }]"
                />
                <van-field
                    :readonly="control.disabled"
                    v-model="dataForm.demand"
                    clearable
                    name="就业需求"
                    label="就业需求"
                    placeholder="请输入就业需求"
                    :rules="[{ required: true }]"
                />
                <van-field
                    :readonly="control.disabled"
                    v-model="dataForm.licensed"
                    clearable
                    name="持证信息"
                    label="持证信息"
                    placeholder="请输入持证信息"
                    :rules="[{ required: true }]"
                />
                <van-cell name="有无就业意愿" title="有无就业意愿"
                          :value="show.employment" :rules="[{ required: true, message: '请选择性别' }]"
                          @click="control.employment = !control.disabled"
                          readonly
                          clickable
                          placeholder="请选择有无就业意愿"
                          :is-link="!control.disabled"
                />
                <van-popup v-model="control.employment" position="bottom">
                  <van-picker
                      show-toolbar
                      value-key="label"
                      :columns="[{label: '有', value: 1}, {label: '无', value: 2}]"
                      @confirm="employmentConfirm"
                      @cancel="control.employment = false"
                  />
                </van-popup>
                <van-cell name="失业时间" title="失业时间"
                          :value="dataForm.unemploymentTime" :rules="[{ required: true, message: '请选择失业时间' }]"
                          @click="control.unemploymentTime = !control.disabled"
                          readonly
                          clickable
                          placeholder="请选择失业时间"
                          :is-link="!control.disabled"
                />
                <van-popup v-model="control.unemploymentTime" position="bottom">
                  <van-datetime-picker
                      v-model="show.unemploymentTime"
                      type="date"
                      title="选择失业时间"
                      :min-date="minDate"
                      :max-date="maxDate"
                      @confirm="unemploymentTimeConfirm"
                      @cancel="control.unemploymentTime = false"
                  />
                </van-popup>
              </van-cell-group>
            </div>
          </van-tab>

          <van-tab title="房屋">
            <div class="info">
              <div v-if="!control.showNew">
                <div v-for="(item,index) in resHouses" v-if="item.moveOut == 0 && item.status == 0">
                  <van-cell class="none">
                    <div class="form-blue-view"></div>
                    <div class="form-title-view">房屋{{ index + 1 }}</div>
                    <div class="grayness-small-character goto blue mini-font-size" style="" @click="gotohouse"
                         v-if="control.disabled">前往房屋
                    </div>
                    <div class="grayness-small-character goto mini-font-size" @click="item.id?moveOutCard(index):removeCard(index)"
                         v-if="!control.disabled">
                         <img :src="require('@/assets/img/problem_delete.png')" alt="" width="24">
                    </div>
                    <div class="grayness-small-character goto mini-font-size" @click="addCard" v-if="!control.disabled">
                      <img :src="require('@/assets/img/problem_add.png')" alt="" width="24">
                    </div>
                  </van-cell>
                  <van-cell
                      readonly
                      clickable
                      name="社区名称"
                      :value="item.communityName"
                      :value-class="{'value-common':item.communityName=='请选择'}"
                      :is-link="!control.disabled"
                      title="社区名称"
                      placeholder="点击选择社区"
                      @click="communityShow(index)"
                  />
                  <van-cell
                      readonly
                      clickable
                      name="小区名称"
                      :value="item.subareaName"
                      :value-class="{'value-common':item.subareaName=='请选择'}"
                      :is-link="!control.disabled"
                      title="小区名称"
                      placeholder="点击选择小区"
                      @click="subareaShow(index)"
                  />
                  <van-cell
                      readonly
                      clickable
                      name="居住房屋"
                      :value="item.fullName"
                      :is-link="!control.disabled"
                      :value-class="{'value-common':item.fullName=='请选择'}"
                      title="居住房屋"
                      placeholder="点击选择居住房屋"
                      @click="houseTreeShow(index)"
                  />
                  <van-cell
                      readonly
                      clickable
                      name="与户主关系"
                      :value="item.relationshipStr"
                      :is-link="!control.disabled"
                      :value-class="{'value-common':item.relationshipStr=='请选择'}"
                      title="与户主关系"
                      placeholder="点击选择与户主关系"
                      @click="relationshipShow(index)"
                  />
                  <van-cell
                      readonly
                      clickable
                      name="房屋属性"
                      :value="item.useTypeStr"
                      :is-link="!control.disabled"
                      :value-class="{'value-common':item.useTypeStr=='请选择'}"
                      title="房屋属性"
                      placeholder="点击选择房屋属性"
                      @click="useTypeShow(index)"
                  />

                  <van-cell title="是否居住" :value="item.live || '请选择'" :value-class="{'value-common':item.subareaName=='请选择'}"
                            :is-link="!control.disabled" @click="control.live = true;houseIndex=index"/>

                  <van-field clearable input-align="left" clear-trigger="always" v-model="resHouses[index].remark"
                             rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="备注"
                             :readonly="control.disabled"/>
                  <div style="height: 10px; width: 100%; background-color:#f5f5f5;"></div>
                </div>
              </div>

              <template v-else-if="control.showNew">
                <div class="page-view page-content-view addHouse" @click="addCard">
                  <div style="margin: 0 auto">
                    <van-icon class="iconSize" color="#409EFF" name="add-o"/>
                    <div class="addHouseFont">创建房屋</div>
                  </div>
                </div>
              </template>
              <van-popup v-model="control.communityPopup" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.communityList.filter(item =>{return dataForm.userResList.map(val =>{return val.orgName}).indexOf(item.label)!=-1})"
                    @confirm="communitySelect($event)"
                    @cancel="control.communityPopup = false"
                />
              </van-popup>
              <van-popup v-model="control.subareaPopup" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.subareaList"
                    @confirm="subareaSelect($event)"
                    @cancel="control.subareaPopup = false"
                />
              </van-popup>
              <van-popup v-model="control.houseTreeCascader" position="bottom">
                <van-cascader
                    title="请选择"
                    value="value"
                    :field-names="{ text: 'label', value: 'value', children: 'children' }"
                    :options="selectList.houseTree"
                    active-color="#1989fa"
                    @close="control.houseTreeCascader= false"
                    @finish="control.houseTreeCascader= false"
                    @change="houseCascaderChange($event)"
                />
              </van-popup>
              <van-popup v-model="control.relationship" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.relationshipList"
                    @confirm="relationshipConfirm($event)"
                    @cancel="control.relationship = false"
                />
              </van-popup>
              <van-popup v-model="control.useType" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.useTypeList"
                    @confirm="useTypeConfirm($event)"
                    @cancel="control.useType = false"
                />
              </van-popup>
              <van-popup position="bottom" v-model="control.live">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="[{label: '是', value: 1}, {label: '否', value: 0}]"
                    @confirm="liveConfirm($event)"
                    @cancel="control.live = false"
                />
              </van-popup>
              <div class="bai">

              </div>
            </div>
          </van-tab>

          <van-tab title="车辆信息" v-if="dataForm.carList.length>0">
            <div class="info">
              <div>
                <div v-for="(item,index) in dataForm.carList" >
                  <van-cell class="none">
                    <div class="form-blue-view"></div>
                    <div class="form-title-view">车辆{{ index + 1 }}</div>
                    <div class="grayness-small-character goto blue mini-font-size" style="" @click="gotoCar(item)"
                         v-if="control.disabled">前往车辆详情
                    </div>
                  </van-cell>
                  <van-field
                      :readonly="control.disabled"
                      v-model="item.subareaStr"
                      clearable
                      name="小区名称"
                      label="小区名称"
                      placeholder="请输入小区名称"
                      :rules="[{ required: true }]"
                  />
                  <van-field
                      :readonly="control.disabled"
                      v-model="item.typeStr"
                      clearable
                      name="车辆类型"
                      label="车辆类型"
                      placeholder="请输入车辆类型"
                      :rules="[{ required: true }]"
                  />
                  <van-field
                      :readonly="control.disabled"
                      v-model="item.carNumber"
                      clearable
                      name="车牌号码"
                      label="车牌号码"
                      placeholder="请输入车牌号码"
                      :rules="[{ required: true }]"
                  />
                  <van-field
                      :readonly="control.disabled"
                      v-model="item.brand"
                      clearable
                      name="车牌品牌"
                      label="车牌品牌"
                      placeholder="请输入车牌品牌"
                      :rules="[{ required: true }]"
                  />
                  <van-field
                      :readonly="control.disabled"
                      v-model="item.temporary == 0 ? '否' : '是' "
                      clearable
                      name="是否临时车"
                      label="是否临时车"
                  />
                  <div style="height: 10px; width: 100%; background-color:#f5f5f5;"></div>
                </div>
              </div>
              <div class="bai">

              </div>
            </div>
          </van-tab>
          <van-tab title="智养医疗" v-if="control.oldShow == true">
            <div class="info">
              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">用户政策信息</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.policyVisible=!control.policyVisible">
                      <van-icon :name="control.policyVisible?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-for="(item, index) in medicalData.policyList" :key="index" v-if="control.policyVisible  && medicalData.policyList.length > 0">
                  <van-field readonly :label="item.name" input-align="right"/>
                </div>
                <van-empty description="暂无用户政策信息" v-if="control.policyVisible && medicalData.policyList.length < 1"/>
              </van-cell-group>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">慢病史</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.slowVisible=!control.slowVisible">
                      <van-icon :name="control.slowVisible?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-for="(item, index) in medicalData.chronicDiseaseList" :key="index" v-if="control.slowVisible && medicalData.chronicDiseaseList.length > 0">
                  <van-field readonly :label="item.name" input-align="right"/>
                </div>
                <van-empty description="暂无慢病史信息" v-if="control.slowVisible && medicalData.chronicDiseaseList.length < 1"/>
              </van-cell-group>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">就诊信息</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.diagnosisVisible=!control.diagnosisVisible">
                      <van-icon :name="control.diagnosisVisible?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-if="control.diagnosisVisible">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.card"
                      name="医保卡号"
                      label="医保卡号"
                      placeholder="未知"
                  />
                  <van-field
                      :readonly="true"
                      v-model="medicalData.hospitalVisits.visitDate"
                      name="就诊日期"
                      label="就诊日期"
                      placeholder="未知"
                  />
                  <van-cell name="诊断信息" title="诊断信息"
                            :value="dataForm.sex == 1 ? '男' : '女'"
                            readonly
                            placeholder="未知"
                  />
                  <van-field clearable input-align="left" clear-trigger="always"
                             v-model="medicalData.hospitalVisits.diagnosis" rows="4" class="left info-title"
                             :border="false" autosize type="textarea" placeholder="诊断信息" :readonly="true"/>
                </div>
              </van-cell-group>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">药品信息</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.prescribeVisible=!control.prescribeVisible">
                      <van-icon :name="control.prescribeVisible ?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-for="(item, index) in medicalData.prescribeList" v-if="control.prescribeVisible && medicalData.prescribeList.length > 0">
                  <van-cell title="药品名称" />
                  <van-field clearable input-align="left" clear-trigger="always" v-model="item.medicine_name"
                             rows="1" class="left info-title" :border="false" autosize type="textarea" placeholder="药品名称"
                             :readonly="true"/>
                  <van-cell title="数量">
                    <van-field
                        :readonly="true"
                        v-model="item.quantity"
                        name="数量"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="一次剂量">
                    <van-field
                        :readonly="true"
                        v-model="item.dosage"
                        name="一次剂量"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="一次剂量单位">
                    <van-field
                        :readonly="true"
                        v-model="item.unit"
                        name="一次剂量单位"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="用药天数">
                    <van-field
                        :readonly="true"
                        v-model="item.days"
                        name="用药天数"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="速配协定诊断名称">
                    <van-field
                        :readonly="true"
                        v-model="item.diagnosis"
                        name="速配协定诊断名称"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-divider :style="{ borderColor: '#ddd'}" />
                </div>
                <van-empty description="暂无药品信息" v-if="control.prescribeVisible && medicalData.prescribeList.length < 1"/>
              </van-cell-group>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">家属签约信息</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.signVisible=!control.signVisible">
                      <van-icon :name="control.signVisible ?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-if="control.signVisible">
                  <van-cell title="医生工号">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.familyDoctor.doctorId"
                        name="医生工号"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="医生姓名">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.familyDoctor.doctorName"
                        name="医生姓名"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="签约开始时间">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.familyDoctor.startTime"
                        name="签约开始时间"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="签约结束时间">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.familyDoctor.endTime"
                        name="签约结束时间"
                        placeholder="未知"
                    />
                  </van-cell>
                </div>
              </van-cell-group>

              <van-cell-group class="info-second">
                <van-cell class="info-title">
                  <template #title>
                    <div class="blue-box"></div>
                    <span class="custom-title">体检信息</span>
                    <div style="color:#387FF5;float:right"
                         @click="control.testVisible=!control.testVisible">
                      <van-icon :name="control.testVisible ?'arrow-up':'arrow-down'"></van-icon>
                    </div>
                  </template>
                </van-cell>
                <div v-if="control.testVisible">
                  <van-cell title="体检小结">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.healthCheck.summary"
                        name="体检小结"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="体检诊断">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.healthCheck.diagnosis"
                        name="体检诊断"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell title="体检时间">
                    <van-field
                        :readonly="true"
                        v-model="medicalData.healthCheck.checkDate"
                        name="体检时间"
                        placeholder="未知"
                    />
                  </van-cell>
                  <van-cell name="建议" title="建议"
                            readonly
                            placeholder="建议"
                  />
                  <van-field clearable input-align="left" clear-trigger="always" v-model="medicalData.healthCheck.suggest"
                             rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="建议"
                             :readonly="true"/>
                </div>
              </van-cell-group>

            </div>
          </van-tab>

          <van-tab v-if="control.psychosis" title="肇事">
            <cause :userId="dataForm.id" :user-name="dataForm.name"/>
          </van-tab>
          <van-tab v-if="control.petition" title="上访">
            <petition :userId="dataForm.id" :user-name="dataForm.name"/>
          </van-tab>
        </van-tabs>

        <van-cell
            style="background-color: #F5F5F5"
        />
        <template v-if="control.isRandomShow">
          <div class="form-btn-view">
            <van-row gutter="10" class="btns">
              <van-col span="24" v-if="!ranDomData.readonly">
                <van-button round block type="info" native-type="submit"
                            @click="dataSubmit()">确认保存
                </van-button>
              </van-col>
              <van-col span="24" v-if="ranDomData.readonly && !ranDomData.checkReason">
                <van-button type="info" size="large" round @click="redact">编辑</van-button>
              </van-col>
            </van-row>
          </div>
        </template>
        <template v-if="!control.isRandomShow">
          <div class="form-btn-view" v-if="active==0||active==1 || active==2">
            <van-row class="btns" v-if="control.disabled">
              <van-col span="12">
                <van-button plain type="danger" size="large" round @click="getMoveOutCommunity()">迁出</van-button>
              </van-col>
              <van-col span="12">
                <van-button type="info" size="large" round @click="redact">编辑</van-button>
              </van-col>
            </van-row>
            <van-row class="btns" v-else>
              <van-col span="24">
                <van-button round block type="info" native-type="submit"
                            @click="submit"
                            v-if="!control.disabled && !dataForm.id">确认添加
                </van-button>
                <van-button round block type="info"
                            @click="submit"
                            v-if="!control.disabled && dataForm.id">确认保存
                </van-button>
              </van-col>
            </van-row>
          </div>
        </template>
      </van-form>

      <div class="form-btn-view" v-if="control.disabled&&(active==0||active==1 || active==2)&& !control.isRandomShow">
        <van-row class="btns">
          <van-col span="12">
            <van-button plain type="danger" size="large" round @click="getMoveOutCommunity()">迁出</van-button>
          </van-col>
          <van-col span="12">
            <van-button type="info" size="large" round @click="redact">编辑</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
    <nation v-if="control.nationShow" @setNation="setNation"/>
    <nationality v-if="control.nationalityShow" @setNationality="setNationality"/>

    <van-popup v-model="control.community" position="bottom">
      <van-picker
          title="请选择迁出社区"
          show-toolbar
          :columns="communityList"
          value-key="label"
          @confirm="onConfirmCommunity"
          @cancel="control.community=false"
      />
    </van-popup>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {getImageStream} from "@/utils/index";
import {formatterDate} from "@/utils/utils";
import {getBirthdayFromIdCard} from "@/utils/utils";
import {getSexFromIdCard} from "@/utils/utils";
import {getNativePlaceByIdCard} from "@/utils/utils";
import {identityCodeValid} from "@/utils/utils";
import {listComRightLabel} from "@/utils/common"
import {getbelongSubArea} from "@/utils/common"
import {upload} from "@/utils/common"
import {getVirtualDict, getDictTree} from "@/utils/common"
import petition from "@/views/userRes/petition";
import cause from "@/views/userRes/cause";
import nation from "@/components/nation/nation";
import nationality from "@/components/nation/nationality";

var that
export default {
  name: 'userResInfo',
  components: {
    nation,
    nationality,
    petition,
    cause
  },
  data() {
    return {
      communityList: [],
      houseIndex: 0,
      death: false,
      collapse: ['1'],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      minDate: new Date(2017, 1, 1),
      maxDate: new Date(2025, 1, 1),
      ranDomData: {
        // 抽查結果
        checkReason: null,
        // 抽查批次id
        reviewId: 0,
        // 数据类型
        dataType: '',
        dataTypeStr: '请选择',
        dataTypeShow: false,
        dataTypeList: [{value: 1, label: '必填信息未填'},{value: 2, label: '字段信息错误'},{value: 3, label: '逻辑错误'},{value: 4, label: '数据冲突'}],
        // 不合格原因
        noCheckReason: '',
        // 传过来的核查id
        adrrId: 0,
        // 不合格原因
        cause: '',
        readonly: false,
        // 督查id
        checkId: 0,
        user: 0,
      },
      dataForm: {
        id: "",
        headImg: "",
        name: "",
        usedName: '',
        orgId: "",
        sex: "",
        nationality: 59,
        idNumberType: "1",
        idNumber: "",
        mobile: "",
        fixedLine: '',
        nation: 2,    //2是汉族
        birthday: "",
        company: "",
        regAddress: "",
        nowAddress: "",
        registryType: "",
        death: "0",
        deathDate: "",
        emContact: "",
        emMobile: "",
        resRemark: "",
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        shorts: [],//简称连接
        hometown: '',
        community: '',
        marriage: '',
        spouseIdNumberType: '',
        education: '',
        political: '',
        religious: '',
        unemploymentTime: '',
        employment: '',
        professional: '',
        amateur: '',
        demand: '',
        licensed: '',
        spouseIdNumber: '',
        userInfoAuxiliaryId: '',
        live: '',
        carList: [],
        userResList: []
      },
      newUserInfo: {
        isAdd: true,
        labels: [],
        labelIds: [],
        orgId: '',
        orgName: '请选择',
        userRes: {
          registryType: '',
          registryTypeStr: '请选择'
        }
      },
      medicalData: {
        card: '',
        familyDoctor: {
          doctorId: '',
          doctorName: '',
          startTime: '',
          endTime: ''
        },
        hospitalVisits: {
          visitDate: '',
          diagnosis: ''
        },
        healthCheck: {
          summary: '',
          diagnosis: '',
          checkDate: '',
          suggest: ''
        },
        prescribeList: [],
        chronicDiseaseList: [],
        policyList: [],
        result: ''
      },
      addMsgShow: false,
      loading: false,
      active: '',
      communityActive: '',
      show: {
        idNumberTypeStr: "身份证",
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: getImageStream("files/wx/images/content/headImg.png"),//展示头像图
        labelStr: '',
        Hometown: '请选择',
        community: '请选择',
        marriage: '请选择',
        spouseIdNumberType: '请选择',
        education: '请选择',
        political: '请选择',
        religious: '请选择',
        unemploymentTime: new Date(),
        employment: '请选择',
        live: '请选择'
      },
      control: {
        addLabel: false,
        addCommunity: false,
        addRegistryType: false,
        disabled: false,
        idNumberType: false,
        birthday: false,
        deathDate: false,
        label: false,
        showNew: true,
        communityPopup: false,
        subareaPopup: false,
        houseTreeCascader: false,
        relationship: false,
        useType: false,
        psychosis: false,
        registryType: false,
        sex: false,
        live: false,
        community: false,
        marriage: false,
        spouseIdNumberType: false,
        education: false,
        religious: false,
        Hometown: false,
        political: false,
        unemploymentTime: false,
        employment: false,
        nationShow: false,
        nationalityShow: false,
        slowVisible: true,
        policyVisible: true,
        prescribeVisible: false,
        diagnosisVisible: false,
        signVisible: false,
        testVisible: false,
        oldShow: false,
        isRandomShow: false
      },
      selectList: {
        idNumberTypeList: [],
        houseTree: [],
        hometownList: [],
        communityList: [],
        subareaList: [],
        spouseIdNumberTypeList: [],
        marriageList: [],
        educationList: [],
        political: [],
        politicalList: [],
        religiousList: []
      },
      resHouses: [],
      temporaryLabel: [],//临时标签
      addTemporaryLabel: [],
      labels: [],
      checkedHouses: [],//目前选中的房屋
    }
  },
  watch: {
    'dataForm.idNumber'(value, oldVal) {
      if (that.dataForm.idNumberType == 1) {
        if (value.length == 18 || value.length == 15) {
          that.dataForm.birthday = getBirthdayFromIdCard(value);
          that.dataForm.sex = getSexFromIdCard(value);
        }
      }
    },
    'dataForm.death'(value) {
      this.death = this.dataForm.death == 1 ? true : false
    },
    death() {
      this.dataForm.death = this.death ? 1 : 0
    }
  },
  computed: {...mapState(['nationality', 'nation'])},
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.unemploymentTime = formatterDate(new Date())
    that = this
    // 扫码跳转吧
    let options = this.$route.query

    if (options.houseFlag){
      this.control.showNew = false
      options.name = ''
      options.houseId = options.id
    }
    this.dataForm.id = parseInt(options.userId) || ''
    this.dataForm.name = options.name || ""
    this.dataForm.idNumberType = 1 || ""
    this.show.idNumberTypeStr = "身份证" || ""
    this.dataForm.idNumber = options.idNumber || ""
    this.dataForm.regAddress = options.regAddress || ""
    this.dataForm.sex = options.sex == "男" ? 1 : 2 || ""
    this.dataForm.nation = options.nation || "2"
    this.show.nationStr = options.nationStr || "汉族"
    this.userName = this.$globalData.userInfo.userName
    this.mobile = this.$globalData.userInfo.mobile


    if (options.houseId) {
      this.resHouses = [{
        id: 0,
        community: options.community,
        subarea: options.subarea,
        houseId: options.houseId,
        relationship: 0,
        useType: 0,
        nowLive: 1,
        remark: '',
        houses: [],
        moveOut: 0,
        registryTypeStr: "未知",
        communityName: options.communityName,
        subareaName: options.subareaName,
        fullName: options.fullName,
        relationshipStr: "请选择",
        useTypeStr: "请选择",
        status: 0,
        live: '',
        allHouseTree: []
      }]
    }
    if (this.$route.query.adrrType) {
      this.dataForm.id = parseInt(options.id)
      this.control.isRandomShow = parseInt(options.isRandom) ? true : false
      this.ranDomData.reviewId =  parseInt(options.reviewId)
      this.ranDomData.adrrId =  parseInt(options.adrrId)
      this.ranDomData.checkReason =  parseInt(options.reviewStatus)
      this.ranDomData.dataType =  parseInt(options.adrrType)
      this.ranDomData.noCheckReason = options.cause
      this.ranDomData.cause = options.cause
      this.ranDomData.readonly = parseInt(options.readonly)
      this.ranDomData.checkId =  parseInt(options.checkId)
      this.ranDomData.user = parseInt(options.user)
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    getMoveOutCommunity () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/allCommunity`),
        method: 'get',
        params: this.$http.adornParams({
          userId: this.dataForm.id,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.communityList = data.list
          if (this.communityList.length == 1) {
            this.onConfirmCommunity(this.communityList[0])
          } else {
            this.control.community = true
          }
        }
      })
    },
    onConfirmCommunity (e) {
      this.$dialog.confirm({
        message: `确认迁出${e.label}？`,
      }).then(() => {
        this.control.community = false
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
          method: 'get',
          params: this.$http.adornParams({
            id: parseInt(this.dataForm.id),
            community: e.value
          })
        }).then(({data}) => {
          if (data.code == 0) {
            that.$toast.success('迁出成功')
            this.$router.go(-1)
          } else {
            that.$toast.fail(data.msg)
          }
        })
      })
    },
    addUserInfo () {
      if (this.newUserInfo.userRes.registryType == '') {
        return this.$toast.fail('请选择户籍性质')
      }
      if (this.newUserInfo.orgId == '') {
        return this.$toast.fail('请选择社区')
      }
      this.dataForm.userResList.push(this.newUserInfo)
      this.newUserInfo = {
        isAdd: true,
        labels: [],
        labelIds: [],
        orgId: '',
        orgName: '请选择',
        userRes: {
          registryType: '',
          registryTypeStr: '请选择'
        }
      }
      this.addMsgShow = false
    },
    deleteUserRes (index) {
      if (this.dataForm.userResList[index].isAdd) {
        this.dataForm.userResList.splice(index, 1)
      } else {
        this.$dialog.confirm({
          message: `确认迁出${this.dataForm.userResList[index].orgName}？`,
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
            method: 'get',
            params: this.$http.adornParams({
              id: parseInt(this.dataForm.id),
              community: this.dataForm.userResList[index].orgId
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.$toast.success('迁出成功')
            } else {
              that.$toast.fail(data.msg)
            }
          })
        })
      }
    },
    setNation(value) {
      if (value) {
        this.dataForm.nation = value.value
        this.show.nationStr = value.label
      }
      this.control.nationShow = false
    },
    setNationality(value) {
      this.dataForm.nationality = value.value
      this.show.nationalityStr = value.label
      this.control.nationalityShow = false
    },
    init() {
      this.selectInfo()
      if (this.$globalData.result) {
        let code = this.$globalData.result
        this.control.oldShow = true
        this.control.disabled = true
        this.active = 3
        this.getMedicalUser(code)
      }
      if (this.dataForm.id) {
        this.control.disabled = true
        this.dataInfo()
      } else {
        that.dataForm.community = this.$globalData.userInfo.orgId
        this.dataForm.orgId = this.$globalData.userInfo.orgId
        //新建的，获取中国
      }
    },
    dataSubmit () {
      if (!this.ranDomData.checkReason) {
        this.$toast.fail('请选择抽查结果')
        return
      }
      if (this.ranDomData.checkReason == 2 && this.ranDomData.dataTypeStr === '请选择') {
        this.$toast.fail('请选择数据类型')
        return
      }
      if (this.ranDomData.checkReason == 2 && this.ranDomData.noCheckReason === '') {
        this.$message.error('不合格原因不能为空')
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/wxapp/dataCalibration/reviewRecord/update`),
        method: 'post',
        data: this.$http.adornData({
          id: this.ranDomData.adrrId,
          reviewId: this.ranDomData.reviewId,
          reviewStatus: this.ranDomData.checkReason,
          cause: this.ranDomData.noCheckReason,
          type: this.ranDomData.dataType
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '成功',
            duration: 1500,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    checkUpdate (isReturn) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/dataCalibration/calibration/update`),
        method: 'post',
        data: this.$http.adornData({
          id: this.ranDomData.checkId
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.code == 0) {
            this.$toast.success({
              message: '成功',
              duration: 1500,
              onClose: () => {
                if (isReturn) {
                  this.$router.go(-1)
                }
              }
            })
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    submit(forceCalibration) {
      let userResList
      if (this.dataForm.userResList && this.dataForm.userResList.length > 0) {
        userResList = this.dataForm.userResList.map(item => {
          return {
            orgId: item.orgId,
            registryType: item.userRes.registryType,
            labels: item.labelIds.toString()
          }
        })
      } else {
        return that.$toast.fail('请添加所在社区及相关户籍类型！')
      }
      let nativePlace = this.dataForm.hometown
      let numberBool = identityCodeValid(that.dataForm.idNumber)
      if (this.dataForm.idNumberType == 1 && numberBool == '身份证号格式错误') {
        return that.$toast.fail(numberBool)
      }
      if (this.dataForm.name.trim() == '') {
        return this.$toast.fail('请输入姓名')
      }
      if (this.dataForm.idNumber.trim() == '') {
        return this.$toast.fail('请输入身份证')
      }
      if (this.dataForm.mobile.trim() == '') {
        return this.$toast.fail('请输入电话号码')
      }
      let registryTypeCheck = this.dataForm.userResList.some(item => {
        return !item.userRes.registryType
      })
      if (registryTypeCheck) {
        return this.$toast.fail('请选择户籍类型')
      }
      let calibrationType = this.ranDomData.dataType || undefined
      forceCalibration = forceCalibration == 0 || forceCalibration == 1 ? forceCalibration : undefined
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/save`),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          userResList,
          nativePlace,
          calibrationType,
          forceCalibration
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.control.disabled = true
          this.dataForm.id = data.userId
          if (this.resHouses.length != 0) {
            this.resHouses.forEach(item => {
              if (!item.userId) {
                item.userId = this.dataForm.id
              }
              if (item.live == '否') {
                item.nowLive = 0
              } else {
                item.nowLive = 1
              }
            })
            if (!!this.ranDomData.checkId) {
              this.checkUpdate()
            } else {
              this.$toast.success({
                message: '成功',
                duration: 1500,
                onClose: () => {
                  this.$router.go(-1)
                }
              })
            }
            console.log(this.resHouses)
            this.$http({
              url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
              method: 'post',
              data: this.$http.adornData({resHouses: JSON.stringify(this.resHouses)})
            }).then(({data}) => {
              this.$toast.clear()
              if (data.code == 0) {
                this.$toast.success('成功')
                this.control.disabled = true
              }
            })
          } else {
            this.$toast.clear()
            this.active = 2
            this.$toast.fail('请添加房屋')
          }
        }  else if (data.code == 100) {
          this.$toast.clear()
          this.$dialog.confirm({
          message: `${data.msg}，是否确认信息正确?`
        }).then(() => {
          this.submit(1)
        }).catch(() => {
          // this.$router.go(-1)
        })
      }
        let employmentDemand = this.dataForm.demand
        let licensedinformation = this.dataForm.licensed
        let isWish = this.dataForm.employment
        let id = this.dataForm.userInfoAuxiliaryId
        this.$http({
          url: this.$http.adornUrl(`/wxapp/app/userinfoauxiliary/save`),
          method: 'post',
          data: this.$http.adornData({
            amateur: this.dataForm.amateur,
            employmentDemand: employmentDemand,
            id: id,
            isWish: isWish,
            licensedinformation: licensedinformation,
            professional: this.dataForm.professional,
            registerTime: this.dataForm.registerTime,
            userId: this.dataForm.id
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.control.disabled = true
          } else {
            this.$toast.fail(data.msg)
          }
        })
        // this.$router.go(-1)
      })
    },
    dataInfo() {
      this.dataFormInfo();
      this.resHousesInfo();
      this.getUserinfoauxiliary()
      this.getCarList();
    },
    getMedicalUser(code) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getByCode`),
        method: 'post',
        params: this.$http.adornParams({
          code: code
        })
      }).then(({data}) => {
        if (data.code == 0) {
          let result = data.result.id_card
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          if (data.result.hospital_visits.prescribe_list !== null) {
            that.medicalData.prescribeList = data.result.hospital_visits.prescribe_list
          }
          this.getMedicalUserInfo(result)
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalCare() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getHealthRecord`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.prescribeList = data.result.hospital_visits !== null ? data.result.hospital_visits.prescribe_list : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalUserInfo(result) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/userInfo`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: result
        })
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.fixedLine = data.userInfo.fixedLine || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
          that.show.nationStr = data.userInfo.nationStr || "未知"
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          that.dataForm.idNumberType = data.userInfo.idNumberType || '1'
          that.dataForm.education = data.userInfo.education || ""
          that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
          that.dataForm.political = data.userInfo.political || ""
          that.dataForm.usedName = data.userInfo.usedName || ""
          that.dataForm.hometown = data.userInfo.nativePlace || ""
          that.dataForm.religious = data.userInfo.religious || ""
          that.dataForm.marriage = data.userInfo.marriage || ""
          that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
          that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
          that.show.marriage = data.userInfo.marriageStr || '请选择'
          that.show.religious = data.userInfo.religiousStr || '请选择'
          that.show.education = data.userInfo.educationStr || '请选择'
          that.show.political = data.userInfo.politicalStr || '请选择'
          that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            // that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

            // that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          }
          // 居民标签
          that.dataForm.userResList = data.userResList
          if (data.userResList && data.userResList.length > 0) {
            data.userResList.forEach(item => {
              var resLabels = item.resLabel
              var labels = []
              if (resLabels != null) {
                let shorts = that.dataForm.shorts
                for (let i in resLabels) {
                  let resLabel = resLabels[i]
                  if (resLabel.shortName) {
                    shorts.push(resLabel.shortName)
                  }
                }
                labels = that.formatLabel(resLabels, true);

                if (shorts.indexOf("精") > -1) {
                  that.control.psychosis = true
                }

                if (shorts.indexOf("访") > -1) {
                  that.control.petition = true
                }
              }
              that.labels = labels

              let idsArray = labels.map((item) => {
                item["delete"] = false
                return item.ids
              })
              that.dataForm.labels = that.dataForm.labels.concat(idsArray)
            })
          }
          this.resHousesInfo()
          this.getUserinfoauxiliary()
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getUserinfoauxiliary() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/userinfoauxiliary/info/${this.dataForm.id}`),
        method: 'post',
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.userInfoAuxiliary != null){
            that.dataForm.professional = data.userInfoAuxiliary.professional || ''
            that.dataForm.amateur = data.userInfoAuxiliary.amateur || ''
            that.dataForm.demand = data.userInfoAuxiliary.employmentDemand || ''
            that.dataForm.licensed = data.userInfoAuxiliary.licensedinformation || ''
            that.show.employment = data.userInfoAuxiliary.isWish == 1 ? '有' : '无'
            that.dataForm.employment = data.userInfoAuxiliary.isWish || ''
            that.dataForm.registerTime = data.userInfoAuxiliary.registerTime || new Date()
            that.dataForm.userInfoAuxiliaryId = data.userInfoAuxiliary.id || 0
          }
        }
      })
    },
    getCarList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/devicecar/list`),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: that.$globalData.userInfo.orgId,
          userId: this.dataForm.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.dataForm.carList = data.page.list;
        }
      })
    },
    dataFormInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: parseInt(that.dataForm.id),
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.fixedLine = data.userInfo.fixedLine || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
          that.show.nationStr = data.userInfo.nationStr || "未知"
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          that.dataForm.idNumberType = data.userInfo.idNumberType || '1'
          that.dataForm.education = data.userInfo.education || ""
          that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
          that.dataForm.political = data.userInfo.political || ""
          that.dataForm.usedName = data.userInfo.usedName || ""
          that.dataForm.hometown = data.userInfo.nativePlace || ""
          that.dataForm.religious = data.userInfo.religious || ""
          that.dataForm.marriage = data.userInfo.marriage || ""
          that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
          that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
          that.show.marriage = data.userInfo.marriageStr || '请选择'
          that.show.religious = data.userInfo.religiousStr || '请选择'
          that.show.education = data.userInfo.educationStr || '请选择'
          that.show.political = data.userInfo.politicalStr || '请选择'
          that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            // that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

          }
          // 居民标签
          that.dataForm.userResList = data.userResList
          if (that.dataForm.userResList && that.dataForm.userResList.length > 0) {
            that.dataForm.userResList.forEach(item => {
              var resLabels = item.resLabel
              var labels = []
              if (resLabels && resLabels.length > 0) {
                let shorts = that.dataForm.shorts
                resLabels.forEach(val => {
                  if (val.shortName) {
                    shorts.push(val.shortName)
                  }
                })
                labels = that.formatLabel(resLabels, true);

                if (shorts.indexOf("精") > -1) {
                  that.control.psychosis = true
                }

                if (shorts.indexOf("访") > -1) {
                  that.control.petition = true
                }
              }
              item.labels = labels
              item.labelIds = []
              let idsArray = labels.map((item) => {
                item["delete"] = false
                return item.ids
              })
              item.labelIds = item.labelIds.concat(idsArray)
            })
          }
        }
      })
    },
    //居民房屋列表初始化
    resHousesInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/houseRes/list`),
        method: 'get',
        params: this.$http.adornParams({
          userId: parseInt(that.dataForm.id)
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let userResHouses = data.userResHouses
          // let u = JSON.parse(JSON.stringify(userResHouses))
          for (let i in userResHouses) {
            let house = userResHouses[i]
            house["initSubarea"] = house.orgIds[house.orgIds.length - 1];
            house["initSubareaName"] = house.subareaName
            house["initHouseId"] = house.houseId
            house["initFullName"] = house.fullName
            house["initRelationship"] = house.relationship
            house["initRelationshipStr"] = house.relationshipStr
            house["initUseType"] = house.useType
            house["initUseTypeStr"] = house.useTypeStr
            house["initNowLive"] = house.nowLive
            house['houses'] = []
            house['subarea'] = house.orgIds[house.orgIds.length - 1];
            house['subareaName'] = house.subareaName;
            house['status'] = 0;
            house['live'] = house.nowLive == 1 ? '是' : '否';
            house['remark'] = house.remark;
            this.houseSelect(house.community, i)
            that.checkedHouses.push({
              'index': parseInt(i),
              'houseId': house.houseId
            })
          }
          that.resHouses = data.userResHouses,
              that.houseChange(that.resHouses)
        }
      })
    },
    selectInfo() {
      this.userSelect();
      this.houseSelect();
      this.getDictList()
      this.getCommunity()
    },
    getDictList() {
      getVirtualDict('political', (dict) => {
        this.selectList.politicalList = dict
      })
      getVirtualDict('religious', (dict) => {
        this.selectList.religiousList = dict
      })
      getDictTree({code: 'marriage'}, (dict) => {
        this.selectList.marriageList = dict
      })
      getDictTree({code: 'education'}, (dict) => {
        this.selectList.educationList = dict
      })
    },
    getCommunity() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTreeByDepth`),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$globalData.userInfo.orgId,
          depth: -1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectList.communityList = data.liveWhichTree
        }
      })
    },
    userSelect() {
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })

      //居民标签
      listComRightLabel(function (labels) {
        that.selectList.userCommunityLabelTree = labels
      })

      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
    },
    //房屋信息select加载
    houseSelect(community, index) {
      index = parseInt(index)
      getbelongSubArea(community, function (subAreaList) {
        that.selectList.subareaList = subAreaList
      })
      //居住房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: community
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.allHouseTree = data.liveWhichTree
        }
      })

      //与户主关系
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.relationships.map((r) => {
            r.value = r.value + ""
          })
          that.selectList.relationshipList = data.relationships
        }
      })

      //房屋属性
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/useType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {

          data.useTypes.map((u) => {
            u.value = u.value + ""
          })
          that.selectList.useTypeList = data.useTypes
        }
      })
    },

    headImgButton(e) {
      if (!that.control.disabled) {
        let path = "files/userHeadImg/temporary"
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration: 0,
          overlay: true
        })
        upload(e.file, path, null, function (res) {
          // alert(JSON.stringify(res))
          that.show.headImg = getImageStream(res.fileMessage.relativePath)
          that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
          that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
          that.$toast.clear()
        })
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          appid: 'wxcd8dbbf3f2ba3b14'
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm.name = data.message.name
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.regAddress = data.message.addr
          this.dataForm.birthday = data.message.birthday
          this.dataForm.sex = message.gender == "男" ? 1 : 2
          this.dataForm.nation = data.message.nation
          this.show.nation = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goNation() {
      if (!this.control.disabled) {
        // this.$router.push('/nation')
        this.control.nationShow = true
      }
    },
    goNationality() {
      if (!this.control.disabled) {
        this.control.nationalityShow = true
      }
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    showIdNumberType() {
      if (!this.control.disabled) {
        this.control.idNumberType = true
      }
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    spouseIdNumberTypeConfirm(e) {
      this.dataForm.spouseIdNumberType = parseInt(e.value)
      this.show.spouseIdNumberType = e.label
      this.control.spouseIdNumberType = false
    },
    showBirthday() {
      if (!this.control.disabled) {
        this.control.birthday = true
      }
    },
    birthdayCalendar(e) {
      let date = formatterDate(e)
      this.control.birthday = false
      this.dataForm.birthday = date

    },

    showRegistryType() {
      if (!this.control.disabled) {
        this.control.registryType = true
      }
    },
    registryType(e) {
      this.dataForm.userResList[this.communityActive].userRes.registryType = parseInt(e.value)
      this.dataForm.userResList[this.communityActive].userRes.registryTypeStr = e.label
      this.control.registryType = false
    },
    addRegistryType(e) {
      this.newUserInfo.userRes.registryType = parseInt(e.value)
      this.newUserInfo.userRes.registryTypeStr = e.label
      this.control.addRegistryType = false
    },
    addCommunity(e) {
      this.newUserInfo.orgId = parseInt(e.value)
      this.newUserInfo.orgName = e.label
      this.control.addCommunity = false
    },
    showDeathDate() {
      if (!this.control.disabled) {
        this.control.deathDate = true
      }
    },
    showlabel() {
      if (!this.control.disabled) {
        this.control.label = true
      }
    },
    showHometown() {
      if (!this.control.disabled) {
        this.control.Hometown = true
      }
    },
    showspouseIdNumberType() {
      if (!this.control.disabled) {
        this.control.spouseIdNumberType = true
      }
    },
    showEducation() {
      if (!this.control.disabled) {
        this.control.education = true
      }
    },
    showPolitical() {
      if (!this.control.disabled) {
        this.control.political = true
      }
    },
    showReligious() {
      if (!this.control.disabled) {
        this.control.religious = true
      }
    },
    showMarriage() {
      if (!this.control.disabled) {
        this.control.marriage = true
      }
    },
    hometownFinish(value) {
      console.log(value)
    },
    marriageConfirm(value) {
      this.control.marriage = false
      this.show.marriage = value.label
      this.dataForm.marriage = value.value
    },
    liveConfirm(e) {
      that.resHouses[this.houseIndex].nowLive = e.value
      that.resHouses[this.houseIndex].live = e.label
      this.control.live = false
    },
    educationConfirm(value) {
      this.control.education = false
      this.show.education = value.label
      this.dataForm.education = value.value
    },
    politicalConfirm(value) {
      this.control.political = false
      this.show.political = value.label
      this.dataForm.political = value.value
    },
    religiousConfirm(value) {
      this.control.religious = false
      this.show.religious = value.label
      this.dataForm.religious = value.value
    },
    unemploymentTimeConfirm(value) {
      this.dataForm.unemploymentTime = formatterDate(value)
      this.control.unemploymentTime = false
    },
    employmentConfirm(value) {
      this.control.employment = false
      this.show.employment = value.label
      this.dataForm.employment = value.value
    },
    deathDateCalendar(e) {
      let date = formatterDate(e)
      this.control.deathDate = false
      this.dataForm.deathDate = date
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.dataForm.userResList[this.communityActive].labels) {
        let label = that.dataForm.userResList[this.communityActive].labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.dataForm.userResList[this.communityActive].labels.push(temporaryLabel)
      that.dataForm.userResList[this.communityActive].labelIds.push(temporaryLabel.ids)
    },
    addLabelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.addTemporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in this.newUserInfo.labels) {
        let label = this.newUserInfo.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      this.newUserInfo.labels.push(temporaryLabel)
      this.newUserInfo.labelIds.push(temporaryLabel.ids)
    },
    //标签级联重置
    labelClose(e) {
      this.control.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    addLabelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.addTemporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.dataForm.userResList[this.communityActive].labelIds) {
        let label = that.dataForm.userResList[this.communityActive].labelIds[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.dataForm.userResList[this.communityActive].labelIds.splice(i, 1)
        }
      }
      for (let i in that.dataForm.userResList[this.communityActive].labels) {
        let label = that.dataForm.userResList[this.communityActive].labels[i]
        if (label.value == value) {
          that.dataForm.userResList[this.communityActive].labels[i]["delete"] = true
        }
      }
      that.dataForm.userResList[this.communityActive].labelIds = that.dataForm.userResList[this.communityActive].labelIds
      that.dataForm.userResList[this.communityActive].labels = that.dataForm.userResList[this.communityActive].labels
      this.$forceUpdate()
    },
    removeAddLabel(value) {
      for (let i in this.newUserInfo.labelIds) {
        let label = this.newUserInfo.labelIds[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.newUserInfo.labelIds.splice(i, 1)
        }
      }
      for (let i in this.newUserInfo.labels) {
        let label = this.newUserInfo.labels[i]
        if (label.value == value) {
          this.newUserInfo.labels[i]["delete"] = true
        }
      }
      this.newUserInfo.labelIds = this.newUserInfo.labelIds
      this.newUserInfo.labels = this.newUserInfo.labels
      this.$forceUpdate()
    },
    /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    //编辑
    redact() {
      if (this.control.isRandomShow) {
        this.ranDomData.readonly = false
      } else {
        this.control.disabled = false
      }
    },
    //前往房屋
    gotohouse: function () {
      let houseId = that.checkedHouses[0].houseId
      this.$store.commit('setHouseId', houseId)
      this.$router.push({path: '/house-info', query: {houseId: houseId}})
    },
    //前往车辆详情
    gotoCar: function (item) {
      this.$router.push({path: '/car-add', query: {id: item.id, subarea: item.subarea}})
    },
    //添加房屋
    addCard() {
      if (!that.control.disabled) {
        let has = false;//末尾存不存在迁出的
        let index = this.resHouses.length
        for (let i = (this.resHouses.length - 1); i >= 0; i--) {
          let res = this.resHouses[i]
          // 如果存在了迁出,删除记录，则直接插入到迁出删除记录的上一个
          if (((res.moveOut === 0 && res.status === 0)) && has) {
            if (i !== (this.resHouses.length - 1)) {
              index = i + 1
              break
            }
          } else if (res.moveOut === 1 || res.status === 1) {
            has = true
            if (i === 0) {
              // 如果第一个就是迁出或删除的，则直接插入
              index = 0
              break
            }
          }
        }
        this.resHouses.splice(index, 0, {
          id: 0,
          community: '',
          subarea: '',
          houseId: '',
          relationship: 0,
          useType: 0,
          nowLive: 1,
          remark: '',
          houses: [],
          moveOut: 0,
          registryTypeStr: "未知",
          subareaName: "请选择",
          fullName: "请选择",
          relationshipStr: "请选择",
          useTypeStr: "请选择",
          status: 0,
          live: '',
        })
        // this.$set(that.resHouses,that.resHouses)
        this.houseChange(that.resHouses)
      }
    },
    //删除房屋
    removeCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认删除？',
        }).then(() => {
          if (true) {
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].status = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses

            // this.data.dataForm.fetHouses.splice(index, 1)
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                that.checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                that.checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })
      }
    },
    //迁出
    moveOutCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认迁出？',
        }).then(() => {
          if (true) {
            // this.data.dataForm.resHouses.splice(index, 1)
            // 把迁出的数据移到最后面
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].moveOut = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses
            //
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })

      }
    },
    //房屋信息改变
    houseChange(houseList) {
      if (!houseList) {
        this.control.showNew = false
      } else {
        if (houseList.length === 0) {
          this.control.showNew = true
        } else {
          if (houseList[0].status === 1 || houseList[0].moveOut === 1) {
            this.control.showNew = true
          } else {
            this.control.showNew = false
          }
        }
      }
      that.control.showNew = that.control.showNew
    },
    communityShow (index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.communityPopup = true
      }
    },
    subareaShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.subareaPopup = true
      }
    },
    communitySelect (e) {
      this.houseSelect(e.value, this.houseIndex)
      that.resHouses[this.houseIndex].community = e.value
      that.resHouses[this.houseIndex].communityName = e.label
      //清空居住房屋
      that.resHouses[this.houseIndex].fullName = "请选择"
      that.resHouses[this.houseIndex].houseId = ""
      this.resHouses = that.resHouses
      this.control.communityPopup = false
    },
    subareaSelect(e) {
      that.resHouses[this.houseIndex].subarea = e.value
      that.resHouses[this.houseIndex].subareaName = e.label
      //清空居住房屋
      that.resHouses[this.houseIndex].fullName = "请选择"
      that.resHouses[this.houseIndex].houseId = ""
      this.resHouses = that.resHouses
      this.control.subareaPopup = false
    },
    //显示居住信息
    houseTreeShow(index) {
      if (!that.control.disabled) {
        // //debugger
        let subarea = that.resHouses[index].subarea

        if (!!(subarea)) {
          let allHouseTree = that.selectList.allHouseTree
          allHouseTree.map((t) => {
            if (t.value == subarea) {
              that.selectList.houseTree = t.children
              return;
            }
          })
          this.control.houseTreeCascader = true
          this.checkedHouseIndex = index
        } else {
          this.$toast.fail("请先选择小区")
        }
      }
    },
    //居住信息改变
    houseCascaderChange(e) {
      let a = (!!(e.selectedOptions[(e.selectedOptions.length - 1)].children))
      if (!a) {
        let houseId = null;
        if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].parameter;
        } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value;
        } else {
          return
        }
        // 防止重复提交一个房间
        if (that.checkedHouses.length > 0) {
          // 先判断有没有
          let exist = false
          let checkedHouses = that.checkedHouses
          for (let i in checkedHouses) {
            let checkedHouse = checkedHouses[i]
            if (checkedHouse.houseId == houseId && this.houseIndex != i) {
              // 如有则提示禁止选择
              that.$toast.fail("房屋信息已存在")
              // that.cancel('房屋信息已存在')
              exist = true
              break
            }
          }
          // 如没有则添加已选择
          if (!exist) {
            let checkedHouses2 = this.checkedHouses
            let save = true
            for (let i in checkedHouses2) {
              let checkedHouse = checkedHouses2[i]
              if (checkedHouse[this.houseIndex] == this.houseIndex) {
                checkedHouse.houseId = houseId
                save = false
              }
            }
            if (save) {
              let a = {'index': this.houseIndex, 'houseId': houseId}
              this.checkedHouses.push(a)
            }
            if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
              this.resHouses[this.houseIndex].houseId = houseId;
              let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
              let fullName = org.substring(0, org.length - 1)
              this.resHouses[this.houseIndex].fullName = fullName
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)
            } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
              this.resHouses[this.houseIndex].houseId = houseId
              this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)
            }
          }
        } else {
          if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
            this.resHouses[this.houseIndex].houseId = houseId;
            let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
            let fullName = org.substring(0, org.length - 1)
            this.resHouses[this.houseIndex].fullName = fullName
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
            this.resHouses[this.houseIndex].houseId = houseId
            this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          }
          let a = {'index': this.houseIndex, 'houseId': houseId}
          this.checkedHouses.push(a)
        }
      }
    },
    relationshipShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.relationship = true
      }
    },
    relationshipConfirm(e) {
      that.resHouses[this.houseIndex].relationship = e.value
      that.resHouses[this.houseIndex].relationshipStr = e.label
      that.control.relationship = false
    },
    useTypeShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.useType = true
      }
    },
    useTypeConfirm(e) {
      that.resHouses[this.houseIndex].useType = e.value
      that.resHouses[this.houseIndex].useTypeStr = e.label
      that.control.useType = false
    },
    sexConfirm(value) {
      this.show.sex = value.value
      this.control.sex = false
    },
    dataTypeConfirm(e) {
      this.ranDomData.dataType = parseInt(e.value)
      this.ranDomData.dataTypeStr = e.label
      this.ranDomData.dataTypeShow = false
    },
    //提交
    onSubmit(e) {
      //校验身份证号
      this.$dialog.confirm({
        message: '确认提交？',
      }).then(() => {
        let check = true
        if (that.dataForm.idNumberType == 1 && check) {
          let numberBool = identityCodeValid(that.dataForm.idNumber)
          if (numberBool == '身份证号格式错误') {
            that.$toast.fail(numberBool)
            check = false
          }
        }
        if (check) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({
              'id': that.dataForm.id || undefined,
              'name': that.dataForm.name,
              'mobile': that.dataForm.mobile,
              'fixedLine': that.dataForm.fixedLine,
              'sex': that.dataForm.sex,
              'nationality': that.dataForm.nationality,
              'nation': that.dataForm.nation,
              'idNumberType': that.dataForm.idNumberType,
              'idNumber': that.dataForm.idNumber,
              'birthday': that.dataForm.birthday,
              'regAddress': that.dataForm.regAddress,
              'nowAddress': that.dataForm.nowAddress,
              'headImg': that.dataForm.headImg,
              'company': that.dataForm.company,
              // 'registryType': that.dataForm.registryType,
              // 'labels': that.dataForm.labels.toString(),
              'death': parseInt(that.dataForm.death),
              'deathDate': that.dataForm.deathDate,
              'resRemark': that.dataForm.resRemark,
              'emMobile': that.dataForm.emMobile,
              'emContact': that.dataForm.emContact,
              'orgId': that.dataForm.orgId,
              'temporaryHeadImg': that.dataForm.temporaryHeadImg,
              'temporaryHeadImgSuffix': that.dataForm.temporaryHeadImgSuffix
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.uploadHouse(data.userId)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
    },
    getInfoByIdNumber () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/getByIdNumber`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber,
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.formInfo(data)
        }
      })
    },
    formInfo(data) {
      if (data.code == 0) {
        // 用户基本信息
        that.dataForm.id = data.userInfo.id || 0
        that.dataForm.name = data.userInfo.name || ""
        that.dataForm.mobile = data.userInfo.mobile || ""
        that.dataForm.fixedLine = data.userInfo.fixedLine || ""
        that.dataForm.sex = data.userInfo.sex || ""
        that.dataForm.nationality = data.userInfo.nationality || ""
        that.dataForm.nation = data.userInfo.nation || ""
        that.dataForm.idNumber = data.userInfo.idNumber || ""
        that.dataForm.birthday = data.userInfo.birthday || ""
        that.dataForm.regAddress = data.userInfo.regAddress || ""
        that.dataForm.nowAddress = data.userInfo.nowAddress || ""
        that.dataForm.headImg = data.userInfo.headImg || ""
        that.dataForm.company = data.userInfo.company || ""
        that.dataForm.death = data.userInfo.death || "0"
        that.dataForm.deathDate = data.userInfo.deathDate || ""
        that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
        that.show.nationStr = data.userInfo.nationStr || "未知"
        that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
        that.dataForm.idNumberType = data.userInfo.idNumberType || "1"
        that.dataForm.education = data.userInfo.education || ""
        that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
        that.dataForm.political = data.userInfo.political || ""
        that.dataForm.usedName = data.userInfo.usedName || ""
        that.dataForm.hometown = data.userInfo.nativePlace || ""
        that.dataForm.religious = data.userInfo.religious || ""
        that.dataForm.marriage = data.userInfo.marriage || ""
        that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
        this.$nextTick(() => {
          that.dataForm.mobileCheck = data.userInfo.mobileCheck || 0
          that.dataForm.nowAddressCheck = data.userInfo.nowAddressCheck || 0
          that.dataForm.regAddressCheck = data.userInfo.regAddressCheck || 0
        })
        that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
        that.show.marriage = data.userInfo.marriageStr || '请选择'
        that.show.religious = data.userInfo.religiousStr || '请选择'
        that.show.education = data.userInfo.educationStr || '请选择'
        that.show.political = data.userInfo.politicalStr || '请选择'
        that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
        if (null != data.userInfo.headImg) {
          that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
        }
        // 居民信息
        if (data.userRes !== null) {
          // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
          //   data.userRes.street, data.userRes.community]
          that.dataForm.registryType = data.userRes.registryType + '' || ""
          that.dataForm.emMobile = data.userRes.emMobile || ""
          that.dataForm.emContact = data.userRes.emContact || ""
          that.dataForm.resRemark = data.userRes.remark || ""
          that.dataForm.orgId = data.userRes.community || ""

          that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
        }
        console.log(that.dataForm)
        // 居民标签
        var resLabels = data.resLabels;
        var labels = []
        if (resLabels != null) {
          let shorts = that.dataForm.shorts
          for (let i in resLabels) {
            let resLabel = resLabels[i]
            if (resLabel.shortName) {
              shorts.push(resLabel.shortName)
            }
          }
          //目前只有钱塘区需要智养功能
          if (this.$globalData.userInfo.county == 12345) {
            if (resLabels !== null && resLabels.length > 0) {
              resLabels.map(item => {
                if (item.path.indexOf(",2,") !== -1) {
                  this.control.oldShow = true
                  this.getMedicalCare()
                }
              })
            }
          }
          console.log(resLabels)
          labels = that.formatLabel(resLabels, true);
          console.log(labels)

          let tabs = [
            {
              key: 'userResInfo',
              title: '居民信息',
              content: 'Content of tab 1',
            },
            {
              key: 'houseRes',
              title: '房屋信息',
              content: 'Content of tab 2',
            }]

          if (shorts.indexOf("精") > -1) {
            that.control.psychosis = true
          }

          if (shorts.indexOf("访") > -1) {
            that.control.petition = true
          }
        }
        that.labels = labels

        let idsArray = labels.map((item) => {
          item["delete"] = false
          return item.ids
        })
        that.dataForm.labels = that.dataForm.labels.concat(idsArray)

        this.$nextTick(() => {
          this.$refs.fieldView.init(data.userRes.id, 1, that.dataForm.orgId)
        })
      }
    },
    //上传房屋
    uploadHouse(userId) {
      let resHousess = []
      for (let index in that.resHouses) {
        let resHouse = that.resHouses[index]
        let resHousee = {
          id: resHouse.id,
          userId: userId,
          subarea: resHouse.subarea,
          houseId: resHouse.houseId,
          relationship: resHouse.relationship,
          useType: resHouse.useType,
          nowLive: resHouse.nowLive,
          remark: resHouse.remark,
          moveOut: resHouse.moveOut,
          status: resHouse.status
        }
        resHousess.push(resHousee)
      }
      let check = true
      for (var i = 0; i < resHousess.length; i++) {
        if (!resHousess[i].subarea) {
          check = false
          this.$toast.fail("所在小区不能为空")
          break
        }
        if (!resHousess[i].houseId) {
          check = false
          this.$toast.fail("居住房屋不能为空")
          break
        }
        if (!resHousess[i].relationship && resHousess[i].relationship != 0) {
          check = false
          this.$toast.fail("与户主关系不能为空")
          break
        }
        if (!resHousess[i].useType && resHousess[i].useType != 0) {
          check = false
          this.$toast.fail("房屋属性不能为空")
          break
        }
      }
      if (check) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
          method: 'post',
          data: this.$http.adornData({
            resHouses: JSON.stringify(resHousess)
          })
        }).then(({data}) => {
          if (data.code == 0) {
            if (that.dataForm.id) {
              that.control.disabled = true
              this.$toast.success("编辑成功")
              this.$router.go(-1)
            } else {
              this.$toast.success("保存成功")
              this.$router.go(-1)
            }
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  background-color: #f5f5f5;
}

::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: 100%;
}
::v-deep .van-dialog__header {
  padding: 30px 20px 0;
}
.headImg {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.van-cell {
  align-items: center;
}

::v-deep .van-collapse-item__content {
  padding: 0;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}

.left > > > .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}

.btnPosition {
  position: absolute;
  bottom: 20px;
  width: 720px;
  left: 15px;
}

.ww {
  width: 100%;
}

.userRes {
  height: 160px;
}

.pagemtop {
  margin-top: 30px;
}

.goto {
  float: right;
  margin-left: 20px;
}

.blue {
  color: #007AFF
}

.houses {
  padding-top: 20px;
  background-color: #f5f5f5;
}

.addHouse {
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.addHouseFont {
  margin-left: 4px;
  font-size: 30px;
  color: #409EFF
}

.iconSize {
  font-size: 200px;
}

.bai {
  height: 200px;
}

.mini-font-size {
  font-size: 25px;
}
.crossBtn {
  position: absolute;
  top: 7px;
  right: 6px;
}
</style>
